<template>
<div id="app">
	<notification />
	<div>
		<div class="bg">
			<div class="line line-white"></div>
			<div class="line line-green line-half"></div>
		</div>
		<div class="ds_center-parent">
			<div class="ds_center-child">
				<router-view />
			</div>
		</div>
	</div>
</div>
</template>

<script>
import Notification from '@components/Notification'

export default {
	components: {
		Notification
	}	
}
</script>


<style lang="sass">
.bg
	width: 100vw
	height: 100vh
	position: fixed
	z-index: -5
	
.line
	position: fixed
	left: 0
	right: 0
	top: 0
	bottom: 0
	&-white
		background-color: $color-white
	&-green
		background-color: $color-primary
	&-half
		bottom: 45vh
</style>