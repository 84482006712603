import * as Tus from 'tus-js-client'
import Common from '@services/Common'
import axios from 'axios'

import { EventBus, Names } from '@/EventBus'

const axiosConfig = {
    withCredentials: false,
    crossDomain: true,
    timeout: 30000,
    headers: {
        'X-DefinityShare': 'Client-Request'
    }
}

var baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : ''

async function serviceInfo() {
    if (process.env.NODE_ENV === 'development') {
        axios.defaults.baseURL = baseUrl
    } else {
        if (!baseUrl) {
            const response = await axios.get('/serverinfo', axiosConfig)
            axios.defaults.baseURL = response.data.WebApiOrigin
            baseUrl = response.data.WebApiOrigin
        }
    }
}

function fileInfo(fileId, fileRequestId) {
    let formData = new FormData()
    formData.append('fileId', fileId)
    formData.append('fileRequestId', fileRequestId)

    return axios.post('/file/fileinfo', formData, axiosConfig)
}

function allFiles(token) {
    axiosConfig.headers.Authorization = 'Bearer ' + token;
    return axios.get('/file/allfiles', axiosConfig)
}

function login(formData) {
    return axios.post('/login/login', formData, axiosConfig).catch(function (error) {
        EventBus.$emit(Names.LoginError, error.response)
    });
}
function create(formData) {
    return axios.post('/file/create', formData).catch(function (error) {
        EventBus.$emit(Names.LoginError, error.response)
    });
}

function uploadFile(file, id) {
    if (file)
    {
        let upload = new Tus.Upload(file, {
            endpoint: baseUrl + '/upload?fileRequestId=' + id,
            retryDelays: [0, 1000, 3000, 5000],
            metadata: {
                filename: file.name,
                contentType: 'application/octet-stream'
            },
            onError: function (error) {
                EventBus.$emit(Names.TusUploadError, error)
            },
            onProgress: function (bytesUploaded, bytesTotal) {
                var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
                EventBus.$emit(Names.TusUploadProgress, percentage)
            },
            onSuccess: function () {
                EventBus.$emit(Names.TusUploadFinished)
            }
        })
    
        // Check if there are any previous uploads to continue.
        upload.findPreviousUploads().then(function (previousUploads) {
            // Found previous uploads so we select the first one. 
            if (previousUploads.length) {
                upload.resumeFromPreviousUpload(previousUploads[0])
            }
        }),
            // Start the uploads 
            upload.start()
    }
    }

async function createByApi(formData) {
    await axios.post('/file/createbyapi', formData).catch(function (error) {
        EventBus.$emit(Names.LoginError, error.response)
    });
    EventBus.$emit(Names.TusUploadFinished);
}
async function download(formData) {
    let response = await axios.post('/file/downloadrequest', formData, axiosConfig)
    response = response.data

    window.location = response
}

async function deleteRequest(formData) {
    return axios.post('/file/delete', formData, axiosConfig)
}

async function adminDelete(fileRequestId) {
    let formData = new FormData()
    formData.append('fileRequestId', fileRequestId)
    axiosConfig.headers.Authorization = 'Bearer ' + sessionStorage.getItem('accessToken')
    return axios.post('/file/admindelete', formData, axiosConfig)
}

async function getRemainSpace(token) {
    axiosConfig.headers.Authorization = 'Bearer ' + token;
    return axios.get('/file/getremainspace', axiosConfig)
}

async function getAllowedUsers() {
    axiosConfig.headers.Authorization = 'Bearer ' + sessionStorage.getItem('accessToken')
    return axios.get('/file/allowedusers', axiosConfig)
}

export default {
    serviceInfo,
    create,
    createByApi,
    allFiles,
    adminDelete,
    download,
    deleteRequest,
    fileInfo,
    uploadFile,
    login,
    getRemainSpace,
    getAllowedUsers
}