const validate = (value, { target }) => {

    var result = false;
    if (String(value).includes("@zat.cz")) {
        result = true;
    }
    if (String(target).includes("@zat.cz"))
    {
        result = true;
    }
    return result;
    
};
const options = {
    hasTarget: true
};
const paramNames = ['target'];

export {
    validate,
    options,
    paramNames
};

export default {
    validate,
    options,
    paramNames
};