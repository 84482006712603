<template>
    <div>
        <div class="form_field padding-left" id="gRecaptcha"></div>
    </div>
</template>

<script>
import Localize from '@localize'
import Common from '@services/Common'

import { EventBus, Names } from '@/EventBus'

export default {
    mounted () {
        Common.initRecaptcha()
    },
    computed: {
        localization () {
            return Localize.validationMessage()
        }
    },
    methods: {
        reset () {
            return Common.resetRecaptcha()
        },
        isValid () {
            let token = Common.getToken()
            if (token) {
                return true
            }
            console.log('invalid ')
            EventBus.$emit(Names.ShowError, this.localization.recaptcha_invalid)
            return false
        },
        getToken () {
            return Common.getToken()
        }
    }
}
</script>

<style lang="sass">
#recaptchaErrorMessage
    display: none
    text-align: center

#gRecaptcha
    display: flex
    justify-content: center
    align-items: center
</style>

