<template>
    <div id="notification" class="notification success hide">
        <h5 class="title">
            {{ title }}
            <button class="close" @click="close()" @keydown.esc="close()" @keydown.enter="close()" tabindex="11">
                <i class="fas fa-times-circle"></i>
            </button>
        </h5>
        <p class="message">
            {{ message }}
        </p>
    </div>
</template>

<script>
import Localize from '@localize'

import { EventBus, Names } from '@/EventBus'

export default {
    data () {
        return {
            title: '',
            message: '',
            notificationClass: ['info', 'error', 'success']
        }
    },
    computed: {
        notification () {
            return document.getElementById('notification')
        },
        localization () {
            return Localize.notification()
        }
    },
    created () {
        EventBus.$on(Names.ShowError, function (message) {
            this.showError(message)
        }.bind(this))
        EventBus.$on(Names.ShowSuccess, function (message) {
            this.showSuccess(message)
        }.bind(this))
        EventBus.$on(Names.ShowInfo, function (message) {
            this.showInfo(message)
        }.bind(this))
        EventBus.$on(Names.HideNotification, function () {
            this.close()
        }.bind(this))
    },
    methods: {
        bindProperties (title, message) {
            this.title = title,
            this.message = message
        },
        clearClass () {
            for (let index = 0; index < this.notificationClass.length; index++) {
                const cssClass = this.notificationClass[index]
                this.notification.classList.remove(cssClass)
            }
        },
        showError (message) {
            this.bindProperties(this.localization.error, message)
            this.clearClass()

            this.notification.classList.add('error')
            this.showNotification()
        },
        showInfo (message) {
            this.bindProperties(this.localization.info, message)
            this.clearClass()

            this.notification.classList.add('info')
            this.showNotification()
        },
        showSuccess (message) {
            this.bindProperties(this.localization.success, message)
            this.clearClass()

            this.notification.classList.add('success')
            this.showNotification()
        },
        showNotification () {
            if (this.notification.classList.contains('hide')) {
                this.notification.classList.remove('hide')
            } else {
                this.notification.classList.add('animation_flicker')
                setTimeout(function () {
                    this.notification.classList.remove('animation_flicker')
                }.bind(this), 500)
            }
        },
        close() {            
            this.notification.classList.add('hide')
        }
    }
}
</script>

<style lang="sass">
.notification
    position: fixed
    min-width: 300px    
    min-height: 100px
    max-width: 400px
    max-height: 50vh
    padding: 0
    margin: 0
    background-color: $color-white
    box-shadow: 0 0 4px 0 rgb(0,0,0)
    box-shadow: 0 0 40px 0 rgba(0,0,0,.35)
    z-index: 1000
    right: 20px
    top: 20px
    transition: transform 250ms ease-in-out
    @include responsive(laptops) 
        .notification
            right: 10px
            top: 10px
    .title
        color: $color-white
        margin: 0
        padding: 20px
        font-size: 1.5rem
    .message
        margin: 0
        padding: 20px
    .close
        color: $color-white
        float: right
        margin: 0
        padding: 0
        font-size: 1.5rem
        border: none
        outline: none       
        &:hover, &:focus
            color: rgba(255, 255, 255, 0.5)
        &:active
            color: rgba(255, 255, 255, 0.75)            
        &:after
            content: ''
            clear: both
    &.hide
        transform: translate(150%)
    &.success
        .title
            background-color: $color-success
        .message
            color: $color-success
        .close
            background-color: $color-success
    &.error
        .title
            background-color: $color-error
        .message
            color: $color-error
        .close
            background-color: $color-error
    &.info
        .title
            background-color: $color-info
        .message
            color: $color-info
        .close
            background-color: $color-info
</style>
