<template>
    <div :id="cardId" class="card animation_fadeFlyInLeft">
        <div class="card_header">
            <div>
                <img class="card_image" src="@/assets/zat_logo.png" alt="CompanyLogo">
            </div>
            <div style="margin-left: 10px;align-self: flex-end;">
                <slot name="title" />
                <slot name="subtitle" />
            </div>
        </div>
        <div style="margin-bottom:10px">
            <slot name="title_msg" />
        </div> 
        <slot name="content">
            <div class="card_content">
                <div class="card_part">
                    <slot name="content-left" />
                </div>
                <div class="card_split"></div>
                <div class="card_part">
                    <slot name="content-right" />
                </div>
            </div>
        </slot>
        <div class="card_footer">
            <slot name="footer" />
            <div class="copyright">
                <p>&copy; {{ year }} <a :href="url" target="_blank">ZAT a.s.</a></p>
                <p><slot name="version" /> {{ version }}</p>
                <p><a href="/admin"><slot name="login" /></a></p>
            </div>
        </div>
    </div>  
</template>

<script>
export default {
    props: {
        cardId: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            url: 'https://www.zat.cz',
            year: new Date().getFullYear(),
            version: '2.0.2'
        }
    }
}
</script>


<style lang="sass">
.copyright
    opacity: 0.45
    & *
        text-align: center

.card
    margin: 0
    background-color: $color-white
    color: $color-black
    box-shadow: 0 0 2px 0 rgb(0,0,0)
    box-shadow: 0 0 20px 0 rgba(0,0,0,.35)
    width: 100vw
    padding: 20px
    &_content
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        width: 100%
        margin-bottom: 20px
    &_header
        display: flex
        margin-bottom: 20px
        font-size: 1.25rem
    &_image
        margin-bottom: 20px
        width: 80px
        height: 80px
        flex: 0 0
    &_title
        font-weight: 800
        flex: 0 1
    &_title_msg
        font-weight: 800
        flex: 1 1
    &_split
        min-width: 100%
        max-width: 100%
        min-height: 1px
        margin-top: 20px
        margin-bottom: 20px
        background-color: $color-primary-white
    &_part
        max-width: 49%
        min-width: 320px
    &_footer
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center

@include responsive(laptops) 
    .card
        margin: 0
        padding: 40px
        max-width: 850px
        &_content
            flex-direction: row
            align-items: stretch
        &_header 
            flex-direction: row
        &_image
            order: 0
            margin: 0
        &_title
            font-weight: 600
        &_split
            min-width: 1px
            max-width: 1px
            min-height: 90%
</style>
