<template>
    <div>
        <ul style="margin:10px">
            <li style="padding-right:10px; font-size:larger" v-for="(item, index) in mainList" :key="index">
                {{ item }}
            </li>
        </ul>
    </div>
</template>

<script>
import Localize from '@localize'

export default {
    computed: {
        mainList () {
            return Localize.mainList()
        }
    }
}
</script>

<style lang="sass">
%base_li
    padding-left: 20px
    padding-right: 10px
    margin-bottom: 10px
    position: relative
    &::before
        color: $color-secondary
        content: counter(li-counter) '.'
        counter-increment: li-counter
        position: absolute
        left: 0
        
ol.list
    list-style-type: none
    counter-reset: li-counter
    background-color: $color-white            
    color: $color-black
.li
    &-big
        @extend %base_li
        font-weight: 800
        font-size: 1.1rem         
    &-small
        @extend %base_li
        font-size: 1rem
</style>
