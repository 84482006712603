var notFound = {
    title: 'Soubor nenalezen',
    description: 'Soubor nebyl nalezen, nebo byl smazán',
    uploadFile: 'Nahrát soubor'
}

var message = {
    fileUploaded: 'Soubor úspěšně nahrán na server',
    fileDeleted: 'Soubor úspěšně smazán ze serveru',
    fileAlreadyExist: 'Soubor již na serveru existuje',
    passwordInvalid: 'Heslo je nesprávné',
    somethingWentWrong: 'Něco se pokazilo'
}

var validationMessage = {
    invalid: 'Pole je neplatné',
    valid: 'Pole je platné',
    recaptcha_invalid: 'ReCaptcha není potvrzena'
}

var uploadForm = {
    label: {
        senderName: 'Jméno a příjmení odesílatele',
        senderEmail: 'Email odesílatele',
        otherEmails: 'Adresáti',
        password: 'Heslo',
        addPassword: 'Chránit heslem',
        confirmPassword: 'Potvrdit heslo',
        showPassword: 'Zobrazit heslo',
        addMessage: 'Přidat zprávu',
        message: 'Zpráva',
        file: 'Vyberte soubor',
        dropFile: 'nebo přetáhněte sem'
    },
    validationMessage
}

var downloadForm = {
    label: {
        password: 'Heslo',
        name: 'Vaše jméno'
    },
    validationMessage
}

var deleteForm = {
    label: {
        password: 'Heslo',
    },
    validationMessage
}

var uploadView = {
    label: {
        title: 'Pomocí aplikace DEFShare zajistíte, že odesílané informace se nedostanou do nepovolaných rukou.',
        upload: 'Nahrát',
        uploading: 'Nahrávám ...',
        done: 'Hotovo'
    },
    list: [
        'DEFShare slouží k zabezpečenému přenosu velkoobjemových souborů mezi zákazníkem nebo externím dodavatelem a společností ZAT a.s.',
        'Povinnými položkami jsou: jméno a příjmení, email odesílatele, email(y) adresáta (pro oddělení více adres použijte čárku nebo středník).',
        'Pro vyšší zabezpečení doporučujeme pro přenos použít zadání jednorázového hesla (volitelné). Heslo není součástí generovaného mailu a je nutné ho zaslat separátně!',
        'Maximální velikost souboru je 4GB (pro nahrání větších souborů je třeba je rozdělit na části).',
        'Platnost souboru je omezena na 30 kalendářních dní, po uplynutí této doby dojde ke smazání souboru.'
    ],
    version: 'Verze',
    login: 'Přihlásit',
    message
}

var deleteView = {
    label: {
        name: 'Název souboru',
        size: 'Velikost',
        delete: 'Smazat',
        deleting: 'Mažu soubor ...',
        uploadFile: 'Nahrát soubor'
    },
    message
}

var downloadView = {
    label: {
        name: 'Název souboru',
        size: 'Velikost',
        download: 'Stáhnout',
        downloading: 'Stahuji ...',
        uploadFile: 'Nahrát soubor'
    },
    message
}

var notFoundView = {
    title: 'Stránka neexistuje',
    description: 'Tato url na této síti neexistuje.',
    uploadFile: 'Nahrát soubor'
}

var login = {
    loginUnauthorized: 'Neplatné přihlašovací údaje',
    loginForbid: 'Nemáte dostatečné oprávnění pro správu souborů',
    loginAuthorize: 'Pro správu souborů je potřeba se přihlásit',
    loginLogout: 'Odhlášení proběhlo úspěšně',
    tokenExpire: 'Bylo provedeno automatické odhlášení'
}

var notification = {
    success: "Úspěch",
    error: "Chyba",
    info: "Informace"
}

export default {
    uploadForm,
    downloadForm,
    deleteForm,
    uploadView,
    downloadView,
    deleteView,
    notFoundView,
    notification,
    notFound,
    validationMessage,
    login
}