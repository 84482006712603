<template>
    <card>
        <h3 slot="title">DEFShare</h3>
        <h5 slot="subtitle"><i>Digital exchange of files</i></h5>
        <h4 slot="title_msg">{{ localization.label.title }}</h4>
        <help-list slot="content-left" />
        <upload-form ref="form" slot="content-right" />
        <re-captcha ref="recaptcha" slot="content-right" />
        <button slot="footer" class="button_submit" :class="{ 'button-disabled': isUploading || isUploaded }"
            :disabled="isUploading || isUploaded" v-on:click="submitForm()">
            <span v-if="!isUploading && !isUploaded">{{ localization.label.upload }}</span>
            <span v-if="isUploading && !isUploaded">{{ localization.label.uploading }} {{ percentage }} %</span>
            <span v-if="!isUploading && isUploaded">{{ localization.label.done }}</span>
        </button>
        <span slot="version">{{ localization.version }}</span>
        <span slot="login">{{ localization.login }}</span>
    </card>
</template>

<script>
import Card from '@components/Card'
import HelpList from '@components/HelpList'
import UploadForm from '@components/UploadForm'
import ReCaptcha from '@components/ReCaptcha'
import Localize from '@localize'
import Api from '@services/Api'
import Common from '@services/Common'

import { EventBus, Names } from '@/EventBus'

export default {
    props:
    {
        loading: { type: Boolean }
    },
    components: {
        Card,
        HelpList,
        UploadForm,
        ReCaptcha
    },
    data() {
        return {
            uploading: false,
            uploaded: false,
            percentage: 0
        }
    },
    computed: {
        localization() {
            return Localize.uploadView()
        },
        isUploading() {
            return this.uploading
        },
        isUploaded() {
            return this.uploaded
        }
    },
    mounted() {
        EventBus.$on(Names.TusUploadProgress, function (percentage) {
            console.log('percentage', percentage)
            NProgress.set(percentage)
            this.percentage = percentage
        }.bind(this))
        EventBus.$on(Names.TusUploadFinished, function () {
            this.uploaded = true
            this.uploading = false
            this.$refs.form.reset()
            this.$refs.recaptcha.reset()

            this.notify({ status: 200 })
        }.bind(this))
        EventBus.$on(Names.TusUploadError, function (error) {
            console.error(error)
            this.loading = false;
            NProgress.done();
            this.notify({ status: 500 })
        }.bind(this))
        EventBus.$on(Names.FileSelected, function () {
            // otevrit tlacitko nahravani
            this.uploaded = false
            this.uploading = false
        }.bind(this))
    },
    methods: {
        async submitForm() {
            if (this.$refs.form.isFormValid() && this.$refs.recaptcha.isValid()) {
                await this.createAndUpload()
            }
        },
        async createAndUpload() {
            try {
                NProgress.start();
                this.uploading = true
                this.loading = true;
                await Api.serviceInfo()
                var id = Common.generateRequestId(this.$refs.form.getFile()).toString();
                if (id) {
                    id = id.replace('-','');
                    console.log(id);
                    await this.create(id)
                    await this.upload(id);
                }

                NProgress.done()
                this.loading = false;
            } catch (error) {
                NProgress.done()
                this.loading = false;
                this.uploading = false
                this.uploaded = false
                console.error(error)

                this.$refs.recaptcha.reset()
                this.notify(error.response)
            }
        },
        async upload(id) {
            await Api.uploadFile(this.$refs.form.getFile(), id)
        },
        async create(id) {
            let formData = this.$refs.form.createFormData()
            formData.append('token', "eyJEZWZTaGFyZSI6IlRva2VuIHBybyBBUEkiLCJhbGciOiJIUzI1NiJ9")
            formData.append('fileId', id)
            await Api.create(formData)
        },
        notify(response) {
            if (response != null && response.status == 200) {
                EventBus.$emit(Names.ShowSuccess, this.localization.message.fileUploaded)
            } else if (response.status == 409) {
                EventBus.$emit(Names.ShowInfo, this.localization.message.fileAlreadyExist)
            } else {
                EventBus.$emit(Names.ShowError, this.localization.message.somethingWentWrong)
            }
        }
    }
}
</script>
