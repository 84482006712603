/* global grecaptcha */
function initRecaptcha() {
    setTimeout(() => {
        if (grecaptcha && document.getElementById('gRecaptcha') && !document.getElementById('gRecaptcha').children.length) {
            grecaptcha.render('gRecaptcha', {'sitekey': '6Lc4TpAaAAAAANFvmuGmcnkMgpVimWYlU65NCE3t'})
        }
    }, 1000);
}

function getToken () {
    if (grecaptcha && document.getElementById('gRecaptcha') && document.getElementById('gRecaptcha').children.length) {
        return grecaptcha.getResponse()
    }
    return ''
}

function resetRecaptcha () {
    if (grecaptcha && document.getElementById('gRecaptcha') && document.getElementById('gRecaptcha').children.length) {
        grecaptcha.reset()
        return true
    }
    return false
}

function generateRequestId(file) {

    const date = new Date();
    let str = date + file.name;

    var hash = 0, i, chr;
    if (str.length === 0) {
        return hash;
    } 

    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

function getReadableFileSizeString(fileSizeInBytes) {
    var i = -1
    var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB']
    do {
        fileSizeInBytes = fileSizeInBytes / 1024
        i++
    } while (fileSizeInBytes > 1024)

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i]
}

export default {
    initRecaptcha,
    resetRecaptcha,
    getToken,
    generateRequestId,
    getReadableFileSizeString
}