import CZ from './cz'
import EN from './en'

function isCzech() {
    var language = navigator.language || navigator.userLanguage
    if (language == 'cs' || language == 'cs-CZ')
        return true
    else
        return false
}

function mainList() {
    return isCzech() ? CZ.uploadView.list : EN.uploadView.list
}

function notFound() {
    return isCzech() ? CZ.notFound : EN.notFound
}

function uploadForm() {
    return isCzech() ? CZ.uploadForm : EN.uploadForm
}
function downloadForm() {
    return isCzech() ? CZ.downloadForm : EN.downloadForm
}
function deleteForm() {
    return isCzech() ? CZ.deleteForm : EN.deleteForm
}

function uploadView() {
    return isCzech() ? CZ.uploadView : EN.uploadView
}
function downloadView() {
    return isCzech() ? CZ.downloadView : EN.downloadView
}
function deleteView() {
    return isCzech() ? CZ.deleteView : EN.deleteView
}

function notification() {
    return isCzech() ? CZ.notification : EN.notification
}

function validationMessage() {
    return isCzech() ? CZ.validationMessage : EN.validationMessage
}

function notFoundView() {
    return isCzech() ? CZ.notFoundView : EN.notFoundView
}

function login() {
    return isCzech() ? CZ.login : EN.login
}

export default {
    uploadForm,
    downloadForm,
    deleteForm,
    uploadView,
    downloadView,
    deleteView,
    notFoundView,
    notification,
    notFound,
    validationMessage,
    mainList,
    login
}