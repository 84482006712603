const validate = (value) => {

    if (typeof value === 'undefined' || value === null || value === '') {
        return false;
    }
    var result = /^[\s]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\s]*([,; ])+[\s]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\s]*$/.test(value);
    return result;

};
const options = {
    hasTarget: false
};

export {
    validate,
    options
};

export default {
    validate,
    options
};