import Vue from 'vue'
import VeeValidate from 'vee-validate';
import App from './App.vue'
import router from './router'
import zatEmail from './custom-rules/zatEmail';
import multipleEmails from './custom-rules/multipleEmails';
import msal from 'vue-msal'
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
import { Validator} from 'vee-validate';

Vue.use(VueGoodTablePlugin);
Vue.use(VeeValidate);
Validator.extend('zatEmail', zatEmail);
Validator.extend('multipleEmails', multipleEmails);

var url = "https://" + window.location.hostname;
Vue.use(msal, {
    auth: {
        clientId: process.env.VUE_APP_CLIENTID,
        authority: process.env.VUE_APP_AUTHORITY,
        redirectUri: url,
        postLogoutRedirectUri: url,
        validateAuthority: false
    },
    request: {
        scopes: ['user.read', 'profile']
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true
    }
})

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
