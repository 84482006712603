// Init EventBus
import Vue from 'vue';
const EventBus = new Vue();

// EventBus event names
const Names = {
    FileSelected: 'fise', 
    // Notification
    ShowError: 'sher',
    ShowSuccess: 'shsu',
    ShowInfo: 'shin',
    // Http requests
    HttpError: 'httperr',
    TusUploadError: 'tusuper',
    TusUploadProgress: 'tusuppr',
    TusUploadFinished: 'tusupfi',
	CreateFormData: 'crfoda',
	LoginError: 'logerr'
}
export {
    EventBus,
    Names
}